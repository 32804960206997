import moment from "moment/moment";

/**
 * Format overall score. Return value will between 0 - 1
 * @param score: number
 */
export function format_overall_score(score: number): number {
  return parseInt((score * 100).toFixed(0));
}

/**
 * Format date
 * @param value: Date in string format
 */
export function date_format(value: string): string {
  return moment(value).format("DD/MM/YYYY LT");
}
